import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      50: '#ffe1e6',
      100: '#ffb1b9',
      200: '#ff7f8b',
      300: '#ff4c5e',
      400: '#ff1a2f',
      500: '#e60016',
      600: '#b40010',
      700: '#81000a',
      800: '#500004',
      900: '#210000',
    },
  },
  components: {
    Toast: {
      variants: {
        error: {
          container: {
            bg: 'gray.800', // ustawienie koloru tła na gray.800
          },
        },
      },
    },
  },
});

export default theme;
