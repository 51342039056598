import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Icon,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  SettingsIcon,
  HamburgerIcon,
  SunIcon,
  StarIcon,
} from '@chakra-ui/icons';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Context } from '../Context';
import useApi from '../hooks/useApi';

export function NavBar({ children }) {
  const rolesClaim =
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/roles';
  const adminRole = 'parking:admin';
  const { user } = useAuth0();
  const { apiCall } = useApi();
  const userRoles = user[rolesClaim];
  const { parkingPlace } = useContext(Context);
  const [shouldNotRenderMap, setShouldNotRenderMap] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiCall({
        url: `/city`,
      });

      if (result?.prefix === 'Warsow-') {
        setShouldNotRenderMap(true);
      }
    };

    fetchData();
  }, [apiCall]);
  const linkItems = [
    { name: 'Free Places', icon: CalendarIcon, to: '/' },
    {
      name: 'Map',
      icon: StarIcon,
      to: '/map',
      isHidden: shouldNotRenderMap,
    },
    {
      name: 'Your Place',
      icon: CalendarIcon,
      to: '/your-place',
      isHidden: !parkingPlace?.placeNumber,
    },
    {
      name: 'List of Places',
      icon: SettingsIcon,
      to: '/admin',
      isHidden: !userRoles.includes(adminRole),
    },
    {
      name: 'List of Slots',
      icon: SunIcon,
      to: '/list-of-slots',
      isHidden: !userRoles.includes(adminRole),
    },
    {
      name: 'Block List',
      icon: SunIcon,
      to: '/block-list',
      isHidden: !userRoles.includes(adminRole),
    },
  ].filter(Boolean);

  const userEmail = user != null ? user.email : null;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        linkItems={linkItems}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent linkItems={linkItems} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} userEmail={userEmail} />
      <Box p={4} ml={{ base: 0, md: 60 }}>
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ linkItems, onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          RockParking
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {linkItems.map(
        link =>
          !link.isHidden && (
            <NavItem key={link.name} icon={link.icon} to={link.to}>
              {link.name}
            </NavItem>
          )
      )}
    </Box>
  );
};

const NavItem = ({ icon, children, to, ...rest }) => {
  return (
    <Link
      as={NavLink}
      to={to}
      _activeLink={{ fontWeight: 'bold', color: 'brand.500' }}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'brand.500',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, userEmail, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      color="white"
      alignItems="center"
      bg="brand.600"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<HamburgerIcon />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        RockParking
      </Text>

      <Flex alignItems={'center'}>
        <Text alignItems={'center'}>Welcome, {userEmail}</Text>
      </Flex>
    </Flex>
  );
};
