import { useState } from 'react';
import ParkingSlotsTable from '../components/ListOfSlot';
import { Input } from '@chakra-ui/react';

function ListOfSlots() {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div style={{ margin: '1rem', padding: '1rem' }}>
        <Input
          type="date"
          value={date}
          onChange={e => setDate(e.target.value)}
        />
      </div>
      <div>
        <ParkingSlotsTable selectedDate={date} />
      </div>
    </div>
  );
}

export default ListOfSlots;
