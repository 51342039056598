import { ColorModeScript, ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import { Auth0ProviderWithNavigate } from './authorization/auth0-provider-with-navigate';
import { ProtectedLayout } from './components/ProtectedLayout';
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <ProtectedLayout>
          <App />
        </ProtectedLayout>
      </ChakraProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
