import { Center, Flex, Spinner } from '@chakra-ui/react';

export const CenteredSpinner = () => (
  <Flex
    width={'100vw'}
    height={'100vh'}
    alignContent={'center'}
    justifyContent={'center'}
  >
    <Center>
      <Spinner color="brand.500" />
    </Center>
  </Flex>
);
