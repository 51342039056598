import React, { useCallback, useEffect, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Box,
  Button,
  Heading,
  HStack,
  Select,
  Stack,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { toDateStr, fromDateToDateStr } from '../helpers/dateHelpers';
import useApi from '../hooks/useApi';
import { CustomCalendar } from '../components/CustomCalendar';

export const YourPlace = () => {
  const [selectedDates, setDates] = useState({});
  const [data, setData] = useState([]);
  const theme = useTheme();
  const { apiCall, isLoading } = useApi();
  const [selectedPlace, setSelectedPlace] = useState('');
  const fetchData = useCallback(async () => {
    const data = await apiCall({ url: '/parking-slots/owner' });
    setData(data);
    if (selectedPlace === '') {
      setSelectedPlace(data[0].placeNumber);
    }
  }, [apiCall, selectedPlace]);

  const handlePlaceSelection = event => {
    const selectedValue = event.target.value;
    setSelectedPlace(selectedValue);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const mapSlot = slot => {
    let event = {
      title: 'Released',
      allDay: true,
      date: toDateStr(slot.date),
      display: 'background',
      isBooked: slot.isBooked,
    };
    if (slot.isBooked) {
      event.title = `Already Booked ${slot.bookedBy}`;
      event.color = theme.colors.orange['400'];
    }
    return event;
  };
  let events = [];
  if (data.length !== 0) {
    const listOfSlots =
      data.find(item => item.placeNumber === selectedPlace)?.slots ?? [];
    events = listOfSlots.map(x => mapSlot(x));
  }
  const eventDates = events.map(x => x.date);
  const placeNumbers = data.map(x => x.placeNumber);

  const isReleased = dateStr => {
    return eventDates.includes(dateStr);
  };

  const isBooked = dateStr => {
    const isBooked =
      events.findIndex(event => event.date === dateStr && event.isBooked) > -1;
    return isBooked;
  };

  const selectAllow = selectInfo => {
    let released = null;
    for (
      var d = selectInfo.start;
      d < selectInfo.end;
      d.setDate(d.getDate() + 1)
    ) {
      const currentDateReleased = isReleased(fromDateToDateStr(d));
      if (released === null) {
        released = currentDateReleased;
      }
      if (released !== currentDateReleased) {
        return false;
      }
    }
    return true;
  };

  const onRelease = async (release = true) => {
    const body = {
      start: selectedDates.start,
      end: selectedDates.end,
      placeNumber: selectedPlace,
    };
    await apiCall({
      url: `/parking-places/${release ? 'release' : 'unrelease'}`,
      method: 'POST',
      body,
    });
    fetchData();
  };

  return (
    <Box>
      <VStack spacing={4}>
        <Heading>
          Your Place:
          {data.length === 1 ? (
            data[0]?.placeNumber
          ) : (
            <Stack>
              <Select onChange={handlePlaceSelection}>
                {placeNumbers.map(el => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Select>
            </Stack>
          )}
        </Heading>

        <CustomCalendar
          events={events}
          eventBackgroundColor={theme.colors.green['400']}
          plugins={[dayGridPlugin, interactionPlugin]}
          select={selectInfo => {
            const endDate = new Date(selectInfo.end);
            endDate.setDate(selectInfo.end.getDate() - 1);
            console.log(selectInfo, endDate);
            setDates({
              start: selectInfo.startStr,
              end: fromDateToDateStr(endDate),
              isReleased: isReleased(selectInfo.startStr),
              isBooked: isBooked(selectInfo.startStr),
            });
          }}
          selectAllow={selectInfo => selectAllow(selectInfo)}
        />
        <HStack spacing={4} align="center">
          <Button
            hidden={!selectedDates.isReleased || selectedDates.isBooked}
            isLoading={isLoading}
            onClick={() => onRelease(false)}
            colorScheme="brand"
            size="lg"
          >
            Unrelease
          </Button>
          <Button
            hidden={selectedDates.isReleased}
            isLoading={isLoading}
            onClick={() => onRelease()}
            colorScheme="green"
            size="lg"
          >
            Release
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
