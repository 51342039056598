import { useEffect, useState } from 'react';
import useApi from '../hooks/useApi';
import { DataTable } from './DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { HStack, IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';

function ParkingSlotsTable({ selectedDate }) {
  const { apiCall } = useApi();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiCall({
        url: `/parking-slots/all?day=${selectedDate}`,
      });
      setData(result);
    };

    fetchData();
  }, [apiCall, selectedDate]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('placeNumber', {
      cell: info => info.getValue(),
      header: 'Place Number',
    }),
    columnHelper.accessor('owner', {
      cell: info => info.getValue(),
      header: 'Owner',
    }),
    columnHelper.accessor('bookedBy', {
      cell: info => info.getValue(),
      header: 'Booked By',
    }),
    columnHelper.accessor('registrationNumber', {
      cell: info => info.getValue(),
      header: 'Registration Number',
    }),
    columnHelper.accessor('comment', {
      cell: info => info.getValue(),
      header: 'Comment',
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => <RowActions row={row.original} />,
    }),
  ];

  const RowActions = ({ row }) => {
    const navigate = useNavigate();

    return (
      <HStack spacing={4}>
        <IconButton
          size="xs"
          onClick={() =>
            navigate(`/list-of-slots/${row.placeNumber}/${selectedDate}`)
          }
          colorScheme="green"
          aria-label="Edit place"
          icon={<EditIcon />}
        />
      </HStack>
    );
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        striped
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50]}
        noHeader
      />
    </>
  );
}
export default ParkingSlotsTable;
