import { useEffect, useState } from 'react';
import useApi from './useApi';

const useFetch = ({
  url,
  method = 'GET',
  defaultValue = {},
  condition = true,
}) => {
  const [data, setData] = useState(defaultValue);
  let { apiCall, isLoading } = useApi();

  useEffect(() => {
    if (condition === false) {
      return;
    }
    const fetchData = async () => {
      const data = await apiCall({ url, method });
      setData(data);
    };
    fetchData();
  }, [url, method, apiCall, condition]);

  return { data, isLoading: condition ? isLoading : false };
};

export default useFetch;
