import React from 'react';
import { Box } from '@chakra-ui/react';
import { ParkingSpaces } from '../components/ParkingSpaces';

export const AdminPage = () => {
  return (
    <Box>
      <ParkingSpaces />
    </Box>
  );
};
