import React, { useContext, useEffect, useState } from 'react';
import './styles/fullcalendar.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { AdminPage } from './pages/Admin';
import { PlaceForm } from './pages/PlaceForm';
import { YourPlace } from './pages/YourPlace';
import { MapPage } from './pages/MapPage';
import { Context } from './Context';
import ListOfSlots from './pages/AdminSlots';
import { AdminSlotForm } from './pages/AdminSlotForm';
import { BlockList } from './pages/BlockList';
import { useAuth0 } from '@auth0/auth0-react';
import useApi from './hooks/useApi';

export default function App() {
  const { parkingPlace } = useContext(Context);
  const rolesClaim =
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/roles';
  const { user } = useAuth0();
  const userRoles = user[rolesClaim];
  const { apiCall } = useApi();
  const [shouldRenderMap, setShouldRenderMap] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiCall({
        url: `/city`,
      });

      if (result?.prefix === 'Warsow-') {
        setShouldRenderMap(false);
      }
    };

    fetchData();
  }, [apiCall]);

  const adminRole = 'parking:admin';

  const hasAdminRole = () => userRoles.includes(adminRole);

  const requireAdminRole = element => {
    return hasAdminRole() ? element : <Navigate to="/" />;
  };

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {parkingPlace?.placeNumber && (
        <Route path="/your-place" element={<YourPlace />} />
      )}
      <Route path="/admin" element={requireAdminRole(<AdminPage />)} />
      <Route
        path="/admin/add-place"
        element={requireAdminRole(<PlaceForm />)}
      />
      {shouldRenderMap ? <Route path="/map" element={<MapPage />} /> : null}
      <Route
        path="/admin/edit-place/:placeNumber"
        element={requireAdminRole(<PlaceForm />)}
      />
      <Route
        path="/list-of-slots"
        element={requireAdminRole(<ListOfSlots />)}
      />
      <Route
        path="/list-of-slots/:placeNumber/:date"
        element={requireAdminRole(<AdminSlotForm />)}
      />
      <Route path="/block-list" element={requireAdminRole(<BlockList />)} />
      <Route
        path="/you-are-banned"
        element={
          <h1>
            Your account has been blocked by administration. Please Contact with
            Office to resolve this problem
          </h1>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
