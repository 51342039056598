import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NavBar } from './NavBar';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { CenteredSpinner } from './CenteredSpinner';
import { ContextProvider } from '../Context';

export const ProtectedLayout = ({ children }) => {
  var Wrapper = () => (
    <ContextProvider>
      <NavBar>
        <Box
          p="4"
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
        >
          {children}
        </Box>
      </NavBar>
    </ContextProvider>
  );

  const Component = withAuthenticationRequired(Wrapper, {
    onRedirecting: () => <CenteredSpinner />,
  });
  return <Component />;
};
