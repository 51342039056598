import { DataTable } from './DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Flex, HStack, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import useApi from '../hooks/useApi';

export const ListOfBlocked = () => {
  const { apiCall } = useApi();
  const [data, setData] = useState([]);
  let fetchData = useCallback(async () => {
    const result = await apiCall({ url: `/users` });
    setData(result);
  }, [apiCall]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('email', {
      cell: info => info.getValue(),
      header: 'Email',
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <RowActions
          row={row.original}
          setIsBlocked={isBlocked =>
            updateIsBlocked(row.original.email, isBlocked)
          }
        />
      ),
      header: 'Actions',
    }),
  ];

  const updateIsBlocked = async (email, isBlocked) => {
    const updatedData = data.map(d =>
      d.email === email ? { ...d, isBlocked: !isBlocked } : d
    );
    setData(updatedData);

    await apiCall({
      url: `/users/${email}`,
      method: 'PUT',

      body: { isBlocked: !isBlocked },
    });
    await fetchData();
  };

  const RowActions = ({ row, setIsBlocked }) => {
    const handleClick = () => {
      setIsBlocked(row.isBlocked);
    };

    return (
      <HStack spacing={4} alignItems="center">
        <Button
          onClick={handleClick}
          colorScheme={row.isBlocked ? 'green' : 'red'}
          w="80px"
          h="32px"
        >
          {row.isBlocked ? 'Unblock!' : 'Block!'}
        </Button>
      </HStack>
    );
  };

  return (
    <VStack spacing={4} align="stretch">
      <Flex justifyContent="left"></Flex>
      <DataTable columns={columns} data={data} />
    </VStack>
  );
};
