import React from 'react';
import FullCalendar from '@fullcalendar/react';

export function CustomCalendar(props) {
  const calendarRef = React.createRef();
  return (
    <FullCalendar
      selectable
      ref={calendarRef}
      firstDay={1}
      fixedWeekCount={false}
      initialView="dayGridMonth"
      height="auto"
      {...props}
    />
  );
}
