import React from 'react';
import { Box } from '@chakra-ui/react';
import rockParkingMap from './../helpers/rockParkingMap.jpg';

export const MapPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={rockParkingMap} alt="Mapa parkingu" />
    </div>
  );
};

export default MapPage;
