import React from 'react';
import {
  Button,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  VStack,
  FormErrorMessage,
  Heading,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import useApi from '../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { toDateStr } from '../helpers/dateHelpers';

export const AdminSlotForm = () => {
  const { placeNumber, date } = useParams();
  const toast = useToast();

  const { data, isLoading } = useFetch({
    url: `/parking-slots/all?day=${date}&placeNumber=${placeNumber}`,
    condition: placeNumber !== undefined,
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ values: data[0] });
  const navigate = useNavigate();
  const { apiCall } = useApi();

  const onSubmit = async data => {
    const method = 'PUT';
    const body = { ...data, date };
    const response = await apiCall({ url: '/parking-slots', method, body });
    if (response === '') {
      toast({
        description: 'Slot has been updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
        bg: 'green.600',
      });
      navigate('/list-of-slots/');
    }
  };

  return (
    !isLoading && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading size="md">
          The date you are changing: {toDateStr(date)}
        </Heading>
        <VStack align="stretch" spacing={4}>
          <FormControl isInvalid={errors.placeNumber}>
            <FormLabel htmlFor="placeNumber">Place Number</FormLabel>
            <Input
              isDisabled
              {...register('placeNumber', {
                required: 'Place Number is required',
                minLength: 2,
              })}
            />
            <FormErrorMessage>
              {errors.placeNumber && errors.placeNumber.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.owner}>
            <FormLabel>Owner Email Address</FormLabel>
            <Input
              type="email"
              isDisabled
              {...register('owner', {
                required: 'Owner Email Address is required',
              })}
            />
            <FormHelperText>
              Email Address of the parking place owner.
            </FormHelperText>
            <FormErrorMessage>
              {errors.owner && errors.owner.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.bookedBy}>
            <FormLabel>Booked By</FormLabel>
            <Input
              {...register('bookedBy', {
                required: 'Email of a person who booked place',
              })}
            />
            <FormHelperText>who booked this place(email)</FormHelperText>
            <FormErrorMessage>
              {errors.bookedBy && errors.bookedBy.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.registrationNumber}>
            <FormLabel>Registration number</FormLabel>
            <Input
              {...register('registrationNumber', {
                required: 'Registration number is required',
              })}
            />
            <FormHelperText>Registration number of car.</FormHelperText>
            <FormErrorMessage>
              {errors.registrationNumber && errors.registrationNumber.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.comment}>
            <FormLabel>Comment</FormLabel>
            <Input {...register('comment', {})} />
            <FormHelperText>Add comment (Unnecessary) </FormHelperText>
            <FormErrorMessage>
              {errors.comment && errors.comment.message}
            </FormErrorMessage>
          </FormControl>
          <Button colorScheme="brand" isLoading={isSubmitting} type="Submit">
            Submit
          </Button>
        </VStack>
      </form>
    )
  );
};
