import React from 'react';
import {
  Button,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import useApi from '../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { useToast } from '@chakra-ui/react';

export const PlaceForm = () => {
  const { placeNumber } = useParams();
  const toast = useToast();
  const { data: values, isLoading } = useFetch({
    url: `/parking-places/${placeNumber}`,
    condition: placeNumber !== undefined,
  });
  let {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ values });
  const navigate = useNavigate();
  const { apiCall } = useApi();

  const onSubmit = async data => {
    const method = placeNumber !== undefined ? 'PUT' : 'POST';
    const response = await apiCall({
      url: '/parking-places',
      method,
      body: data,
    });
    if (response === '') {
      toast({
        description: 'Place has been updated/added',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
        bg: 'green.600',
      });
      if (
        !response.errors ||
        response.errors[0].code === 'list_of_places_has_been_updated'
      ) {
        navigate('/admin');
      }
    }
  };

  console.log(errors);
  return (
    !isLoading && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack align="stretch" spacing={4}>
          <FormControl isInvalid={errors.placeNumber}>
            <FormLabel htmlFor="placeNumber">Place Number</FormLabel>
            <Input
              {...register('placeNumber', {
                required: 'Place Number is required',
                minLength: {
                  value: 1,
                  message: 'Place Number must have at least 2 characters',
                },
                pattern: {
                  value: /^(?!\s*$)[a-zA-Z0-9]+$/,
                  message:
                    'Place Number cannot contain spaces or special characters',
                },
              })}
            />

            <FormErrorMessage>
              {errors.placeNumber && errors.placeNumber.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.owner}>
            <FormLabel>Owner Email Address</FormLabel>
            <Input
              type="email"
              {...register('owner', {
                required: 'Owner Email Address is required',
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: 'Email cannot contain only spaces',
                },
              })}
            />
            <FormHelperText>
              Email Address of the parking place owner.
            </FormHelperText>
            <FormErrorMessage>
              {errors.owner && errors.owner.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.registrationNumber}>
            <FormLabel>Registration number</FormLabel>
            <Input
              {...register('registrationNumber', {
                required: 'Registration number is required',
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: 'Registration number cannot contain only spaces',
                },
              })}
            />
            <FormHelperText>Registration number of car.</FormHelperText>
            <FormErrorMessage>
              {errors.registrationNumber && errors.registrationNumber.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            colorScheme="brand"
            isLoading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </VStack>
      </form>
    )
  );
};
