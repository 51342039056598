import React from 'react';
import { CenteredSpinner } from './components/CenteredSpinner';
import useFetch from './hooks/useFetch';

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const { data: parkingPlace, isLoading } = useFetch({
    url: '/parking-places/my',
  });

  return (
    <Context.Provider value={{ parkingPlace }}>
      {isLoading ? <CenteredSpinner /> : children}
    </Context.Provider>
  );
};
