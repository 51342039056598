import React from 'react';
import { Box } from '@chakra-ui/react';
import { ListOfBlocked } from '../components/ListOfBlocked';

export const BlockList = () => {
  return (
    <Box>
      <ListOfBlocked />
    </Box>
  );
};
