import { DataTable } from './DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Flex, HStack, IconButton, VStack } from '@chakra-ui/react';
import { FaParking } from 'react-icons/fa';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';

export const ParkingSpaces = () => {
  const { apiCall } = useApi();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const loadData = useCallback(async () => {
    setIsLoading(true);
    let parkingPlaces = await apiCall({ url: '/parking-places' });
    setData(parkingPlaces);
    setIsLoading(false);
  }, [apiCall]);
  const handleRowClick = row => {
    navigate(`edit-place/${row.placeNumber}`);
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('placeNumber', {
      cell: info => info.getValue(),
      header: 'Place Number',
    }),
    columnHelper.accessor('owner', {
      cell: info => info.getValue(),
      header: 'Owner',
    }),
    columnHelper.accessor('registrationNumber', {
      cell: info => info.getValue(),
      header: 'Registration number',
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => <RowActions row={row.original} />,
      header: 'Actions',
    }),
  ];
  const RowActions = ({ row }) => {
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);

    const handleEdit = () => {
      navigate(`edit-place/${row.placeNumber}`);
    };

    const handleDelete = async () => {
      setIsDeleting(true);
      try {
        await apiCall({
          url: `/parking-places/${row.placeNumber}`,
          method: 'DELETE',
        });
        toast({
          description: 'Place has been deleted',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
          bg: 'green.600',
        });
        await loadData();
      } catch (error) {
        console.error(error);
      }
      setIsDeleting(false);
    };

    return (
      <HStack spacing={4}>
        <IconButton
          size="xs"
          onClick={handleEdit}
          colorScheme="green"
          aria-label="Edit place"
          icon={<EditIcon />}
        />
        <IconButton
          size="xs"
          onClick={handleDelete}
          isLoading={isDeleting}
          colorScheme="red"
          aria-label="Delete place"
          icon={<DeleteIcon />}
        />
      </HStack>
    );
  };
  useEffect(() => {
    loadData();
  }, [loadData]);
  if (isLoading) {
    return 'spinner';
  }
  return (
    <VStack spacing={4} align="stretch">
      <Flex justifyContent="left">
        <Button
          onClick={() => navigate('add-place')}
          colorScheme="brand"
          leftIcon={<FaParking />}
        >
          Add Place
        </Button>
      </Flex>
      <DataTable columns={columns} data={data} onRowClick={handleRowClick} />
    </VStack>
  );
};
